/**
 * The main js file for the theme
 *
 * This is the entry point where all modules are imported and compiled.
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Dynamic imports rely on Promises, so we need to polyfill it
 * for IE before anything else runs.
 */
import 'core-js/modules/es.promise';
import FontFaceObserver from 'fontfaceobserver';
import { Breakpoints } from './theme/breakpoints';
import {
	imageLinkQuerySelector, videoLinkQuerySelector, youtubeRegex, youtubePageRegex,
} from './theme/lightboxes/helpers';

class Embark {
	/**
	 * Set variables for common elements, fonts, breakpoints and dynamically
	 * import all modules for the project.
	 */
	constructor() {
		/**
		 * Common elements used throughout the project.
		 */
		this.els = {
			html: document.documentElement,
			body: document.body,
			header: document.getElementById('js-site-header'),
			headerSearch: document.querySelector('.site-header .search-form'),
			navContainer: document.querySelector('.site-header__navigation'),
			mainMenu: document.querySelector('.main-menu'),
			navicon: document.getElementById('js-navicon'),
		};

		/**
		 * Each object in this array has the following parameters:
		 *
		 * path: The filepath, without file extension, to import relative to "./theme/".
		 * test: A test to check if the module should be imported or not. Typically,
		 * this is simply checking to see if an element exists in the DOM.
		 */
		this.imports = [
			{
				path: 'sticky-header',
				test: this.els.header,
			},
			{
				path: 'main-menu',
				test: this.els.mainMenu,
			},
			{
				path: 'filterable-content',
				test: document.querySelector('.em-filterable-content'),
			},
			{
				path: 'stats',
				test: document.querySelector('.js-counter'),
			},
			{
				path: 'page-navigation',
				test: document.querySelector('.em-page-navigation'),
			},
			{
				path: 'pipeline',
				test: document.querySelector('.em-pipeline__program'),
			},
			{
				path: 'em-blocks/panels',
				test: document.querySelector('.em-panels'),
			},
			{
				path: 'forms/file-input',
				test: document.querySelector('input[type="file"]'),
			},
			{
				path: 'forms/ios-select-fix',
				test: document.querySelector('.ios select'),
			},
			{
				path: 'forms/gf-tabindex-fix',
				test: document.querySelector('.ginput_container_consent [type="checkbox"][tabindex], .gform_button[tabindex]'),
			},
			{
				path: 'forms/gf-scroll-to-error',
				test: document.querySelector('.gform_validation_error'),
			},
			{
				path: 'comment-reply',
				test: document.querySelector('#reply-title'),
			},
			{
				path: 'scroll-to-hash-on-load',
				test: window.location.hash,
			},
			{
				path: 'scroll-to-hash-on-click',
				test: document.querySelector('a[href*="#"]'),
			},
			{
				path: 'sliders',
				test: document.querySelector('.slider, .em-image-content-slider__slides'),
			},
			{
				path: 'lightboxes',
				test: (() => {
					const els = [...document.querySelectorAll(`${imageLinkQuerySelector}, ${videoLinkQuerySelector}, .em-filterable-content`)];
					if (els.length) {
						const filtered = els.filter((el) => {
							// Don't initialize lightboxes for YouTube profile or channel links.
							if (el.nodeName === 'A' && el.href.match(youtubeRegex) && el.href.match(youtubePageRegex)) {
								return false;
							}

							return true;
						});

						return filtered.length > 0;
					}

					return false;
				})(),
			},
		];

		/**
		 * Array of font-family names to observe with FontFaceObserver to ensure they load.
		 */
		this.fonts = ['Lato'];

		/**
		 * Breakpoints used throughout the project. These should match up with
		 * the breakpoints defined in src/scss/config/mixins/_media-queries.scss.
		 */
		this.breakpoints = new Breakpoints([
			{
				key: 'xs',
				value: 480,
			},
			{
				key: 'sm',
				value: 640,
			},
			{
				key: 'md',
				value: 850,
			},
			{
				key: 'lg',
				value: 1120,
			},
			{
				key: 'xl',
				value: 1380,
			},
			{
				key: 'menu',
				value: 1090,
			},
		]);

		this.init();
	}

	init = async () => {
		await this.maybePolyfill();
		this.loadFonts();
		this.importModules();
	}

	/**
	 * Load in a bunch of polyfills for Internet Explorer
	 * before we load the rest of the modules.
	 *
	 * @since 2.1.1
	 */
	maybePolyfill = async () => {
		if (document.body.classList.contains('ie')) {
			return import('./theme/ie');
		}

		return true;
	}

	/**
	 * Watch as fonts are loaded and add a class to the html element when they are.
	 * Then set a cookie so that on subsequent page views, head.js can quickly add
	 * the class, avoiding FOUT.
	 *
	 * @since 2.1.1
	 */
	loadFonts = () => {
		if (!this.els.html.classList.contains('fonts-loaded')) {
			Promise.all(this.fonts.map((font) => new FontFaceObserver(font).load()))
				.then(() => {
					this.els.html.classList.add('fonts-loaded');
					document.cookie = 'emFontsLoaded=true; SameSite=None; Secure';
				});
		}
	}

	/**
	 * Load these modules only when they are needed.
	 *
	 * @since 2.1.1
	 */
	importModules = () => {
		this.imports.forEach(({ path, test }) => {
			if (test) {
				import(
					/* webpackChunkName: "[request]" */
					`./theme/${path}`
				);
			}
		});
	}
}

document.addEventListener('DOMContentLoaded', () => {
	window.embark = new Embark();
	document.body.classList.add('loaded');
});
