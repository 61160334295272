/* eslint-disable max-classes-per-file */
import { isTouchDevice } from './helpers';

/**
 * An object that contains a min and max screensize,
 * as well as methods to check if the window is in range
 * of those screensizes.
 *
 * @since 1.0.0
 *
 * @type {Class}
 */
export class Breakpoint {
	constructor(value) {
		this.value = value;
	}

	above() {
		return window.matchMedia(`(min-width: ${this.value}px)`).matches;
	}

	below() {
		return window.matchMedia(`(max-width: ${this.value - 1}px)`).matches;
	}

	aboveOrTouch() {
		return this.above() || isTouchDevice();
	}

	belowOrTouch() {
		return this.below() || isTouchDevice();
	}
}

/**
 * A collection of Breakpoint objects for easy access in your project.
 * Best if these match the breakpoint values you setup in your CSS.
 *
 * @since 1.0.0
 *
 * @type {Class}
 */
export class Breakpoints {
	constructor(sizes) {
		if (sizes.length) {
			sizes.forEach(({ key, value }) => {
				this[key] = new Breakpoint(value);
			});
		}
	}

	/**
	 * Get a breapoint or create a new one if it doesn't exist.
	 *
	 * @param {string|integer} value The breakpoint size to get.
	 * @returns {Breakpoint}
	 */
	getBreakpoint(value) {
		return this[value] || new Breakpoint(value);
	}

	/**
	 * Check if the window width is above the passed breakpoint or pixel value.
	 *
	 * Example...
	 *
	 * import { breakpoints } from '../variables';
	 * if (breakpoints.above('sm')) {...}
	 * if (breakpoints.above(600)) {...}
	 *
	 * @param {string|integer} value The min breakpoint size. Can be either a key matching an existing breakpoint, or an integer.
	 * @returns {boolean}
	 */
	above(value) {
		const bp = this.getBreakpoint(value);
		return bp ? bp.above() : false;
	}

	/**
	 * Check if the window width is above the passed breakpoint or pixel value,
	 * or if the browser is running on a touch device.
	 *
	 * @param {string|integer} value Can be either a key matching an existing breakpoint, or an integer.
	 * @returns {boolean}
	 */
	aboveOrTouch(value) {
		const bp = this.getBreakpoint(value);
		return bp ? bp.aboveOrTouch() : false;
	}

	/**
	 * Check if the window width is below the passed breakpoint or pixel value.
	 *
	 * Example...
	 *
	 * import { breakpoints } from '../variables';
	 * if (breakpoints.below('sm')) {...}
	 * if (breakpoints.below(600)) {...}
	 *
	 * @param {string|integer} value Can be either a key matching an existing breakpoint, or an integer.
	 */
	below(value) {
		const bp = this.getBreakpoint(value);
		return bp ? bp.below() : false;
	}

	/**
	 * Check if the window width is below the passed breakpoint or pixel value,
	 * or if the browser is running on a touch device.
	 *
	 * @param {string|integer} value Can be either a key matching an existing breakpoint, or an integer.
	 * @returns {boolean}
	 */
	belowOrTouch(value) {
		const bp = this.getBreakpoint(value);
		return bp ? bp.belowOrTouch() : false;
	}

	/**
	 * Check if the window width is in between two breakpoints or pixel values.
	 *
	 * Example...
	 *
	 * import { breakpoints } from '../variables';
	 * if (breakpoints.between('sm, 'lg')) {...}
	 * if (breakpoints.between(600, 900)) {...}
	 *
	 * @param {string|integer} min The min breakpoint size. Can be either a key matching an existing breakpoint, or an integer.
	 * @param {string|integer} max The max breakpoint size. Can be either a key matching an existing breakpoint, or an integer.
	 */
	between(min, max) {
		const minBp = this.getBreakpoint(min);
		const maxBp = this.getBreakpoint(max);

		if (minBp && maxBp) {
			return minBp.above() && maxBp.below();
		}

		return false;
	}

	/**
	 * Check if the window width is between the passed breakpoints,
	 * or if the browser is running on a touch device.
	 *
	 * @param {string|integer} min The min breakpoint size. Can be either a key matching an existing breakpoint, or an integer.
	 * @param {string|integer} max The max breakpoint size. Can be either a key matching an existing breakpoint, or an integer.
	 * @returns {boolean}
	 */
	betweenOrTouch(min, max) {
		return this.between(min, max) || isTouchDevice();
	}
}
