/**
 * Query selectors and regular expressions used to match image and video urls.
 *
 * @since 2.1.1
 */

// When used with document.querySelectorAll, this will return all anchors that link to image files.
export const imageLinkQuerySelector = 'a[href*=".jpg"],a[href*=".gif"],a[href*=".png"],a[href*=".svg"]';

// When used with document.querySelectorAll, this will return all anchors that link to video files or urls.
export const videoLinkQuerySelector = '[href*="youtube.com"],[href*="youtu.be"],[href*="vimeo.com"],[href*="wistia.com"],[href*=".mp4"],[href*=".ogg"],[href*=".webm"]';

// Matches urls with video extensions.
export const videoFileRegex = /(.mp4|.ogg|.webm)/;

// Matches all YouTube urls: videos, profiles and channels.
export const youtubeRegex = /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\/?\?(?:\S*?&?v=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/;

// Matches YouTube profiles and channels.
export const youtubePageRegex = /youtube.com\/user|youtube.com\/channel/;

// Matches all Vimeo urls.
export const vimeoRegex = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)|vimeo\.com\/user[0-9]+\/review\/([0-9]+)/;

// Matches all Wistia urls.
export const wistiaRegex = /(?:https?:\/\/(.+)?(wistia.com|wi.st)\/(medias|embed)\/)(.*)/;
