/* eslint-disable no-unused-expressions */
/**
 * Defines helper functions
 *
 * A collection of helpful functions that may be imported into any js partial.
 *
 * @package Embark
 * @since   1.0.0
 */

/**
 * Imports jQuery.
 *
 * @see http://api.jquery.com/
 *
 * @since 1.0.0
 */
import $ from 'jquery';

2;

/**
 * Append a hash to the current URL.
 *
 * @since 1.0.0
 *
 * @param {String} hash
 */
export function addHashToURL(hash) {
	if (window.history.pushState) {
		const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${hash}`;
		window.history.pushState({ path: newurl }, '', newurl);
	}
}

/**
 * Scroll the document to the provided element.
 *
 * @since 1.0.0
 *
 * @param  {Node}    el The node to check.
 * @param  {Integer} offset Offset from the top of the viewport
 */
export function scrollToEl(el, offset) {
	window.scroll({
		behavior: 'smooth',
		left: 0,
		top: el.offsetTop - offset,
	});
}

/**
 * Check if element is fully visible within the viewport.
 *
 * @since 1.0.0
 *
 * @param  {Object}  el HTMLElement or jQuery Object.
 * @return {Boolean}
 */
export function isElementInViewport(el) {
	// Special bonus for those using jQuery.
	if (typeof jQuery === 'function' && el instanceof jQuery) {
		el = el[0];
	}

	const rect = el.getBoundingClientRect();

	return (rect.top >= 0 && rect.left >= 0 && rect.bottom <= document.documentElement.clientHeight && rect.right <= document.documentElement.clientWidth);
}

/**
 * Check whether the user has access to a mouse or not.
 *
 * @since 2.1.0
 */
export const hasMouse = matchMedia('(pointer:fine)').matches || (!!window.MSInputMethodContext && !!document.documentMode);

/**
 * Check the userAgent for common touch devices.
 *
 * @since 1.0.1
 * @since 2.1.0 Use more modern touch detection instead of checking for specific device user agents.
 */
export function isTouchDevice() {
	return !hasMouse && ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
}

/**
 * Get the value of a query parameter within a query string.
 *
 * @since 1.2.0
 * @param {string} variable The query param to get.
 */
export function parseQuery(variable) {
	const query = window.location.search.substring(1);
	const vars = query.split('&');
	for (let i = 0; i < vars.length; i++) {
		const pair = vars[i].split('=');
		if (decodeURIComponent(pair[0]) === variable) {
			return decodeURIComponent(pair[1]);
		}
	}

	return false;
}

/**
 * Disable scrolling of the body element without losing your current scroll position.
 */
export function disableScroll() {
	const { documentElement, body } = document;
	if (!documentElement.classList.contains('no-scroll')) {
		const scrollY = window.scrollY || window.pageYOffset;
		body.style.position = 'fixed';
		body.style.top = `-${scrollY}px`;
		documentElement.classList.add('no-scroll');
	}
}

/**
 * Re-enable scrolling while restoring the scroll position to the right point.
 */
export function enableScroll() {
	const { documentElement, body } = document;
	if (documentElement.classList.contains('no-scroll')) {
		const scrollY = body.style.top;
		body.style.position = '';
		body.style.top = '';
		window.scrollTo(0, parseInt(scrollY || '0') * -1);
		documentElement.classList.remove('no-scroll');
	}
}

/**
 * Get the scroll parent for the provided html node.
 *
 * @link https://stackoverflow.com/questions/35939886/find-first-scrollable-parent
 *
 * @since 2.1.1
 * @param {Node} node The html node to find the scroll parent for.
 * @returns {Boolean}
 */
export function getScrollParent(node) {
	if (node == null) {
		return null;
	}

	if (node.scrollHeight > node.clientHeight) {
		return node;
	}

	return getScrollParent(node.parentNode);
}

/**
 * Wrap an element in another element.
 *
 * @link https://plainjs.com/javascript/manipulation/wrap-an-html-structure-around-an-element-28/
 *
 * @since 2.1.1
 * @param {Node} el Node to wrap in wrapper.
 * @param {Node} wrapper Node that wraps el.
 */
export function wrap(el, wrapper) {
	el.parentNode.insertBefore(wrapper, el);
	wrapper.appendChild(el);
}

/**
 * Get Posts from post factory api.
 * @param  {Object} options     Set or override default args and set params.
 * @return {Object}            	returns post factory API response.
 */
export const emFetchPosts = (params = {}) => {
	// Set domain to current domain and endpoint to post factory.
	const { restUrl } = emJsData; // eslint-disable-line
	const apiUrl = `${restUrl}post_factory`;
	let data = false;

	const defaultParams = {
		post_type: 'any',
		posts_per_page: 8,
		term_counts: false,
		partial: 'media-object',
	};

	Object.keys(params).forEach((key) => {
		defaultParams[key] = params[key];
	});

	const fetchPosts = async () => {
		// Loop through the parameters and format for the query string.
		const esc = encodeURIComponent;
		let query = Object.keys(defaultParams).map((k) => {
			if (k === 'post_types') {
				const types = defaultParams[k];
				return `${esc(k)}=${esc(types.split(','))}`;
			}

			return `${esc(k)}=${esc(defaultParams[k])}`;
		});
		query = query ? `?${query.join('&')}` : '';

		// Fetch the data.
		const response = await fetch(apiUrl + query);
		data = await response.json();
		data = data ? JSON.parse(data) : false;

		return data;
	};

	return fetchPosts();
};

/**
 * SlideToggle.
 *
 * @since 2.1.1
 *
 * @param {String} el 			The element to toggle.
 * @param {String} duration 	The time in seconds in related to css.
 */
export const slideToggle = (el, duration = '.3s') => {
	el.style.transition = `height ${duration} ease`;
	el.style.height = el.style.display === 'none' ? '0px' : `${el.clientHeight}px`;
	el.style.overflow = 'hidden';

	/** Slide down. */
	if (el.clientHeight === 0) {
		// Show the element
		el.style.height = 'auto';
		el.style.display = 'block';
		el.style.removeProperty('padding-top');
		el.style.removeProperty('padding-bottom');

		// get elements full height.
		const height = `${el.clientHeight}px`;

		// set height to 0 then to the full height to
		// trigger transition.
		el.style.height = '0px';
		setTimeout(() => {
			el.style.height = height;
		}, 0);

	/** Slide up. */
	} else {
	/** Set the height as 0px to trigger the slide up animation. */
		el.style.height = '0px';
		el.style.paddingTop = 0;
		el.style.paddingBottom = 0;
	}

	el.addEventListener('transitionend', () => {
		if (el.clientHeight === 0) {
			el.style.display = 'none';
		}
	});
};

/**
 * Force images within the provided element to lazy load.
 *
 * @since 1.0.0
 * @param {Node} el The element to search for images in.
 */
export function forceLazyLoad(el) {
	if (!el) return;

	const images = el.querySelectorAll('img');
	if (images) {
		// Force images to load.
		images.forEach((image) => {
			const { src, srcset, sizes } = image.dataset;

			if (src) {
				image.setAttribute('src', src);
				image.classList.add('loaded');
			}
			if (srcset) {
				image.setAttribute('srcset', srcset);
			}
			if (sizes) {
				image.setAttribute('sizes', sizes);
			}
		});
	}
}
